/* Ensure the HTML and body take up the full height */
html,
body {
  height: 100%;
  margin: 0;
}
/* Make the root element a flex container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Make the main element flexible to take up available space */
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-image: url('../../../public/images/landing.JPG');
  background-size: cover; /* Ensures the image covers the whole area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
/* Container should also be flexible to fill the available space */
.container {
  flex: 1; /* Take up all available space within main */
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

footer {
  background-color: #3c4d5d;
  color: #345645;
  text-align: center;
}
footer a {
  color: #345645;
  padding: 10px, 20px;
  text-decoration: none;
}
footer a:hover {
  color: white;
}

.faq {
  margin: 80px 0;
}

.facebook svg {
  color: #0866ff;
  margin-left: 5px;
  width: 20px;
  height: 20px;
}
.custom-navbar,
.custom-footer {
  background-color: transparent !important;
  /* background-color: #97bd55 !important; */
}
/* .custom-navbar .navbar-brand,
.custom-navbar .navbar-nav .nav-link {
  color: black !important;
} */

.faq {
  text-align: center;
}
.faq-title {
  font-weight: bold;
}
.faq-list {
  width: 70%;
  margin: auto;
  text-align: left;
}
.landing-pic {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 25px;
}
.home-body {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}
.home-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Optional: center text inside the div */
  height: 70vh;
  min-width: 320px; /* Set a minimum width */
  width: 45%;
  padding: 20px; /* Optional: add some padding */
}
.home-content h1 {
  color: #345645;
  text-transform: uppercase; /* Convert text to uppercase */
  text-align: center; /* Center align the text */
  line-height: 1.5; /* Adjust line height for better spacing */
}
.bold-text {
  font-weight: bold; /* Make the text bold */
}
.button-group {
  display: flex;
  gap: 10px;
}

.about-container {
  /* background-color: rgba(212, 186, 129, 0.53); */
  background: linear-gradient(
    to bottom,
    rgba(212, 186, 129, 0.53),
    rgba(235, 214, 167, 0.53)
  );
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
}
.claim-container {
  display: flex;
  flex-direction: column; /* To stack the children vertically */
  align-items: center; /* To center the children horizontally */
  justify-content: center; /* To center the children vertically */
  height: 80vh;
  /* To make the container take the full height of the viewport */
  padding: 20px; /* Optional: Add some padding */
}
.claim-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 80%;
  /* padding: 20px; */
  margin-top: 10%;
}
.claim-form .form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.claim-form .form-control {
  width: 200px;
  margin: auto;
}
.claim-form button {
  margin-top: 20px; /* Add space between the input box and button */
  /* width: 100%;  */
  /* Optional: Make the button take the full width */
}
.profile-showcase-button {
  margin: auto;
  display: flex;
}
.profile-body {
  /* background-color: rgba(235, 214, 167, 0.53); */
  backdrop-filter: blur(10px);
  background: linear-gradient(
    to bottom,
    rgba(212, 186, 129, 0.53),
    rgba(235, 214, 167, 0.53)
  );
  border-radius: 15px;
  padding: 20px;
}

.award-container {
  /* background-color: rgba(255, 255, 255, 0.8); */
  background: linear-gradient(
    to bottom,
    rgba(212, 186, 129, 0.53),
    rgba(235, 214, 167, 0.53)
  );
  backdrop-filter: blur(10px);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
}

.table-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
}

.table-wrapper {
  width: 48%;
  max-height: 600px; /* Set the desired height */
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #fdfbdc;
}

.table-wrapper h3 {
  text-align: center;
}

.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
}
.table-wrapper table th,
.table-wrapper table td {
  border-top: 2px solid #ccc; /* Top border */
  border-bottom: 2px solid #ccc; /* Bottom border */
  border-left: none; /* Remove left border */
  border-right: none; /* Remove right border */
  padding: 8px;
}

.table-wrapper table tbody tr:nth-child(odd) {
  background-color: #ecf0c5; /* Light gray for odd rows */
}

.table-wrapper table tbody tr:nth-child(even) {
  background-color: #ecf3a8; /* Slightly darker gray for even rows */
}

.about-reason {
  text-align: center;
  width: 90%;
  /* background-color: #ccc; */

  padding: 20px;
  margin: 0 auto; /* Center horizontally */
  max-width: 1200px;
}
.about-text {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}
.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  text-align: left; /* Align text to the left */
}
.about-text p {
  /* font-size: 18px; */
  margin-bottom: 20px;
}

.about-text p strong {
  font-weight: bold;
}
.about-reason img {
  /* width: 400px; */
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
.about-reason .row {
  margin-bottom: 30px;
}
.showcase-p {
  text-align: center;
  margin-top: 20px; /* Adjust the spacing as needed */
}
